.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #343a40;
}

.page-centre {
    margin-left: auto;
    margin-right: auto;
}

.message {
    font-size: 24px;
    margin: 20px;
    padding: 10px;
    border-radius: 5px;
    background-color: lightgreen;
    color: darkgreen;
    font-weight: bold;
    text-align: center;
}

.table-responsive {
    width: 90%;
    overflow-x: auto;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    background-color: white;
}

.custom-table th {
    background-color: #343a40;
    color: white;
}

.custom-table th, .custom-table td {
    border: 1px solid #dee2e6;
    padding: 8px;
    text-align: center;
}

.sign-out-button {
    padding: 6px 12px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.sign-out-button:hover {
    background-color: #0056b3;
}

.no-users {
    font-size: 20px;
    margin-top: 20px;
    color: grey;
    text-align: center;
}

.signout-page .content-container.override-margin {

    margin-top: 0;
 }

 @media (max-width: 768px) {
    .center-contents {
        padding: 10px;
    }

    /* Stack table rows as block elements */
    .custom-table, .custom-table thead, .custom-table tbody, .custom-table th, .custom-table td, .custom-table tr {
        display: block;
    }

    /* Hide table headers */
    .custom-table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .custom-table tr { margin: 0 0 1rem 0; }
    
    .custom-table td {
        /* Behave like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

    .custom-table td:before {
        /* Adapt these labels to match your table header */
        content: attr(data-label);
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    /* Custom labels for our specific table, adjust as needed */
    td:nth-of-type(1):before { content: "ID"; }
    td:nth-of-type(2):before { content: "FULL NAME"; }
    td:nth-of-type(3):before { content: "Phone Number"; }
    td:nth-of-type(4):before { content: "Date"; }
    td:nth-of-type(5):before { content: "Time of Sign In"; }
    td:nth-of-type(6):before { content: "Email"; }
    td:nth-of-type(7):before { content: "Purpose"; }
    td:nth-of-type(8):before { content: "Time of Sign Out"; }
}
