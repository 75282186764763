.home-container {
    
    font-size: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.logo-container img {
    width: 18.125em;
    margin-bottom: 1.25em;
}
.home-container {
    position: relative;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}
.admin-login-button {
  
   
    border: none;
    padding: 5px 10px;
    font-size: 12px; /* Smaller font size */
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 100px;
    opacity: 0.5; /* Make it semi-transparent */

    /* Hover effect */
    transition: opacity 0.3s ease;
}

.admin-login-button:hover {
    opacity: 1; /* Fully visible on hover */
}
.fade-in {
    opacity: 1 !important;
}
.zoom-controls {
    position: absolute;
    bottom: 30px; /* This places the controls 30px above the absolute bottom of the content-container */
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.zoom-controls2 {
    position: absolute;
    bottom: 500px; /* This places the controls 30px above the absolute bottom of the content-container */
    right: 10px;
    display: flex;
    
    gap: 5px;
}
.button-container {
    display: flex;
    justify-content: space-between; /* This will push the buttons apart. */
    gap: 30px; /* This will ensure there's a 90px gap between the buttons. */
}
.zoom-controls button {
    padding: 0.3125em 0.625em;
    font-size: 1.25em;
    border: none;
    cursor: pointer;
    background-color: gray;
    box-shadow: 0 0.125em 0.3125em rgba(0, 0, 0, 0.1);
    border-radius: 0.3125em;
    transition: background-color 0.3s;
}

.zoom-controls button:hover {
    background-color: black;
}

.signin-button, .signout-button {

    display: flex;
    justify-content: space-between; /* This will push the buttons apart. */
    gap: 90px; /* This will ensure there's a 90px gap between the buttons. */
      
    flex-direction: column;
    justify-content: space-around;
    --btnColor: #FFD700;
    font: bold 3rem Roboto, sans-serif;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.8);
    text-shadow: 0 0.08em 0 rgba(255, 255, 255, 0.5);
    display: inline-block;
    outline: none;
    cursor: pointer;
    padding: 0.625em 1.25em;
    border-radius: 0.3125em;
    font-size: 2em;
    border: 2px solid;
    border-color: color(var(--btnColor) shade(20%)) color(var(--btnColor) shade(30%)) color(var(--btnColor) shade(40%));
    background-color: var(--btnColor);
    background-image: linear-gradient(var(--btnColor), color(var(--btnColor) shade(10%)));
    box-shadow: 0 0.1em 0.5em rgba(0, 0, 0, 0.1), 0 0.1em 0.2em rgba(0, 0, 0, 0.3), 0 -0.1em 0.07em rgba(0, 0, 0, 0.3) inset, 0 0.1em 0.07em rgba(255, 255, 255, 0.2) inset;
    transition: all 100ms;
    margin-top: 1.875em;
}

.signin-button:hover, .signout-button:hover {
    box-shadow: 0 0.1em 0.5em rgba(0, 0, 0, 0.4), 0 0.1em 0.2em rgba(0, 0, 0, 0.4), 0 -0.1em 0.07em rgba(0, 0, 0, 0.3) inset, 0 0.1em 0.07em rgba(255, 255, 255, 0.2) inset;
    background-color: #FFC400;
}

.signin-button:active, .signout-button:active {
    padding: 0.625em 1.25em 0.5625em;
    margin-top: 1.881em;
    border-color: color(var(--btnColor) shade(30%)) color(var(--btnColor) shade(20%)) var(--btnColor);
    background-image: linear-gradient(color(var(--btnColor) shade(5%)), var(--btnColor));
    box-shadow: 0 0.1em 0.2em rgba(0, 0, 0, 0.1) inset, 0 0.1em 0.1em rgba(0, 0, 0, 0.2) inset, 0.05em 0 0.07em rgba(0, 0, 0, 0.2) inset, -0.05em 0 0.07em rgba(0, 0, 0, 0.2) inset;
    transition-duration: 10ms;
}

.img {
    width: 11.875em;
    max-width: 37.5em;
}
