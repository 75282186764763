.form-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.tabs-container {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.tab, .tab2 {
    padding: 10px 20px;
    cursor: pointer;
    border: 1px solid #ccc;
    transition: background-color 0.3s;
}

.tab:hover, .tab2:hover {
    background-color: #f5f5f5;
}

.tab2.active {
    background-color: #007bff;
    color: white;
}

.container {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.title {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.date, .email-container2 {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

input[type="text"], select, input[type="date"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    flex-grow: 1; /* Allows input to grow and fill the space */
}

.email-container2 select {
    max-width: 150px;
    flex-grow: 0; /* Prevents the select dropdown from expanding too much */
}


.tabs-container {
    margin-bottom:90px;
    width:570px;
    margin-left: auto;
    margin-right: auto;
    margin-top:-174px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: relative;
}
.active {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow */
    border: 2px solid #d5eaff; /* Adds a blue border around the active tab */
}
.tab, .tab2 {
    padding: 10px 20px;
    border-radius: 25px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
    position: relative;
    z-index: 1; /* To ensure the tab stays above the connecting line */
}
body {
    font-family: Arial, sans-serif;
    background-color: #f6f6f6;
}
.container {
    max-width: 600px;
    margin: auto auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.radio-container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust space between items as necessary */
    align-items: flex-start;
    width: 100%;
}

.radio-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

input[type="radio"] {
    width:10px;
    cursor: pointer;
}

label {

    cursor: pointer;
    font-size: 16px;
    flex: 1;
   white-space: nowrap;
    word-wrap: break-word;
    padding-right: 10px;
    margin-top: -3px; /* slight negative margin to better align with radio button */
}
.title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
}
select, input {
    
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    box-sizing: border-box;
}

.date {
    display: flex;
    justify-content: space-between;
}
.date input {
    width: 48%;
}
/* Styling for the form container */
.form-container {
    margin-bottom: 15px;  /* Adjust as needed */
}

/* General button styling */
.navigation-buttons button {
    padding: 10px 20px;          /* Gives buttons consistent size */
    border: none;                /* Removes default borders */
    border-radius: 5px;         /* Rounded corners */
    background-color: #3498db;  /* Sample blue color */
    color: #fff;                /* White text color */
    cursor: pointer;            /* Cursor changes to hand on hover */
    transition: background-color 0.3s ease; /* Transition effect for hover */
}

/* Hover effect for buttons */
.navigation-buttons button:hover {
    background-color: #2980b9;  /* Slightly darker blue on hover */
}

/* If using Bootstrap or a similar framework, you may need to override its default styles by increasing specificity or using the !important rule. */

.navigation-buttons {
    margin-top:90px;
    display: flex;
    justify-content: center;
    gap:370px;
    padding: 20px 0; /* Adds some spacing above and below the buttons */
}

button {
    padding: 10px 20px; /* Increases button size */
    border: none; /* Removes default border */
    border-radius: 5px; /* Rounds the button corners */
    background-color: #007BFF; /* Gives a primary color to the button */
    color: #fff; /* Makes button text white */
    font-size: 16px; /* Makes font a bit larger */
    cursor: pointer; /* Indicates clickable areas */
    transition: background-color 0.3s; /* Smooth color transition for hover effect */
}

button:hover {
    background-color: #0056b3; /* Darkens the button color on hover */
}

button:disabled {
    background-color: #ccc; /* Gray out disabled buttons */
    cursor: not-allowed; /* Indicates non-clickable areas */
}


.tab {
    background-color: #d5eaff;
    color: #91c3fd;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.tab2 {
    background-color: #4c9bfb;
    color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.event-box {

    width: 950px;  /* Adjust as needed */
    height: 600px; /* Adjust as needed */
    border: 2px solid yellow;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;  /* This will give it rounded corners */
    margin-left:auto;
    margin-right:auto;
}

.title {
    font-weight:bold;
}
/* For tablets */
@media only screen and (max-width: 768px) {
    .content-box {
        width: 95%;
        padding: 3%;
    }
}

/* For mobile phones */
@media only screen and (max-width: 480px) {
    .content-box {
        width: 100%;
        padding: 4%;
        border-radius: 15px; /* Adjusting the border-radius for smaller screens */
    }
}

.tabs-container::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 15px; /* offset to account for the left overhang */
    right: 15px; /* offset to account for the right overhang */
    height: 2px;
    background-color: #d5eaff;
    z-index: 0;
}

.tab:hover {
    background-color: #555555;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.fade-out {
    animation-name: fadeOut;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards; 
    animation-duration: 2s;  /* Adjust the duration as you see fit */
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}