.login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}
h2 {
    text-align: center;
    margin-bottom: 20px;
}
button {
    padding: 10px 20px; /* Adds padding inside the buttons */
    margin: 5px; /* Adds space between the buttons */
    border: 1px solid #ccc; /* Adds a border to the buttons */
    border-radius: 4px; /* Rounds the corners of the buttons */
    background-color: #f8f8f8; /* Background color for the buttons */
    cursor: pointer; /* Changes the cursor to a pointer on hover */
}



/* If you want to specifically style the 'Go Back' button */

.input-group {
    margin-bottom: 20px;
}
label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}
input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}
.actions {
    margin-top:20px;
    text-align: center;
}
button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}
button:hover {
    background-color: #0056b3;
}