.h1, .p {
    text-align: center;
}

.center-contents {
    margin-top:100px;
    text-align: center;
    padding: 20px;
}
.no-data {
    margin-top:20px;
}
.page-centre {
    margin-left: auto;
    margin-right: auto;
}

.ExportButton {
    margin-top: 30px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

/* Responsive adjustments for iPad Air and similar screen sizes */
@media (max-width: 1024px) {
    .custom-table th, .custom-table td {
        font-size: 14px;
        padding: 8px;
    }

    .custom-table td {
        word-wrap: break-word; /* For older browsers */
        overflow-wrap: break-word;
        max-width: 150px; /* You can adjust this as needed */
    }
}

/* Media query for smaller screens, e.g., mobile phones */
@media (max-width: 768px) {
    .center-contents {
        padding: 10px;
    }

    /* Stack table rows as block elements */
    .custom-table, .custom-table thead, .custom-table tbody, .custom-table th, .custom-table td, .custom-table tr {
        display: block;
    }

    /* Hide table headers */
    .custom-table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    .custom-table tr { margin: 0 0 1rem 0; }
    
    .custom-table td {
        /* Behave like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
    }

    .custom-table td:before {
        /* Adapt these labels to match your table header */
        content: attr(data-label);
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    /* Custom labels for our specific table, adjust as needed */
    td:nth-of-type(1):before { content: "ID"; }
    td:nth-of-type(2):before { content: "FULL NAME"; }
    td:nth-of-type(3):before { content: "Phone Number"; }
    td:nth-of-type(4):before { content: "Date"; }
    td:nth-of-type(5):before { content: "Time of Sign In"; }
    td:nth-of-type(6):before { content: "Email"; }
    td:nth-of-type(7):before { content: "Purpose"; }
    td:nth-of-type(8):before { content: "Time of Sign Out"; }
}
