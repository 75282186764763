/* This will ensure the body and html take up the full viewport height */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
.content-container {
  margin-top:170px;
  margin-bottom:20px;
  position: relative;
  flex-grow: 1;
}
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;  /* Change this line */
}

/* This will ensure the header stretches across the screen */
header {

  width: 100%;
}

/* This will ensure the footer is always at the bottom */
footer {
  width: 100%;
  margin-top: auto; /* Pushes it to the bottom */
}
footer img {
  display: block;
}

